import React, {useEffect} from 'react';
import {
    Label1, Label2, Label4, Display4, H5, H2,H3,H4
} from 'baseui/typography';
import './App.css';
import {Client as Styletron} from 'styletron-engine-atomic';
import {Provider as StyletronProvider} from 'styletron-react';
import {LightTheme, BaseProvider, styled} from 'baseui';
import {Slider} from "baseui/slider";
import {Chart} from "react-google-charts";
import {Grid, Cell} from 'baseui/layout-grid';
import {useStyletron} from 'baseui';
import {Accordion, Panel} from "baseui/accordion";
import {StyledLink} from "baseui/link";
import * as Papa from 'papaparse';
import {Select} from "baseui/select";
import {Spinner} from "baseui/spinner";
import {Button, KIND} from "baseui/button";
import {SIZE} from "baseui/input";

const engine = new Styletron();

const Columned = styled('div', {
    width: '300px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
});


const SliderParagraph = styled('div', {
    margin: '8px',
    padding: '0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

});

const MiddleScreen = styled('div', {
    height: '400px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

});

const Footer = styled('footer', {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '64px',
    bottom: '0',
    width: '100%',
    height: '2.5rem'

});

const FooterSecond = styled('footer', {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '2.5rem'

});

const Header = styled('footer', {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft:"16px",
    marginRight:"16px",

});
const itemProps = {
    backgroundColor: 'mono300',
    height: 'scale1000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};


export default function App() {

    const [rateOfTransmission, setRateOfTransmission] = React.useState([13.6]);
    const [people, setPeople] = React.useState([10]);
    const [days, setDays] = React.useState([20]);
    const [promil, setPromil] = React.useState([6]);
    const [double, setDouble] = React.useState([6]);
    const [csv, setCsv] = React.useState({});
    const [csvArr, setCsvArr] = React.useState([]);
    const [iso, setIso] = React.useState([]);


    useEffect(() => {

        Papa.parse('https://covid.ourworldindata.org/data/owid-covid-data.csv', {
            download: true,
            header: true,
            complete: function (csv) {
                console.log(csv)
                const groupedByIsoCode = groupBy(csv.data, 'iso_code')
                window.groupedByIsoCode = groupedByIsoCode;
                window.csv = csv.data
                setCsv(groupedByIsoCode)
                setCsvArr(csv.data)
            }
        });

    }, []);


    const groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    return (
        <StyletronProvider value={engine}>
            <BaseProvider theme={LightTheme}>


                {csvArr.length > 0 ? <Grid>
                    <Cell span={12}>

                        <Header>
                            <H2>Risk of Contamination with SARS-COV-2</H2>
                        </Header>
                        <Header>
                            <p>Please note that the actual density of infected people in your area may be much higher (quite often tenfold)
                                than the average confirmed cases per 1000 in the selected country.
                                This holds also for the growth factor and the transmission rate as they both depend significantly
                                on the way of communication.</p>
                        </Header>

                    </Cell>

                    <Cell span={6}>

                        <SliderParagraph>
                            <Select
                                options={Object.keys(csv).map(code => ({
                                    label: csv[code][0].location,
                                    id: code
                                })).sort((a, b) => {

                                    if (a.label && b.label) {
                                        return a.label.localeCompare(b.label)
                                    } else {
                                        return 0
                                    }

                                })}
                                value={iso}
                                placeholder="Select country"
                                onChange={params => {

                                    if (params.value.length > 0) {
                                        let selectedIso = params.value[0].id;
                                        let byIso = csv[selectedIso];
                                        console.log(byIso)
                                        console.log(selectedIso)
                                        let totalCases = Number((byIso[byIso.length - 1].total_cases_per_million / 1000).toFixed(2));

                                        setPromil([totalCases])



                                        const c =   byIso.map(i=>i.total_cases)


                                        let f = (gfactor(c, 14)-1)*100;
                                        let fp = Number(f.toFixed(2));
                                        setDouble([fp])

                                        console.log('totalCases',c)


                                    } else {
                                        setPromil([6])
                                    }

                                    setIso(params.value)

                                }}/>
                        </SliderParagraph>


                        <SliderParagraph>
                            <Label2 style={{width: '300px'}}>Daily contacts</Label2>

                            <Button style={{marginTop: 20, marginRight: 16}}
                                    size={SIZE.mini}
                                    onClick={() => setPeople([people[0] - 1 < 0 ? 0 : people[0] - 1])}>-</Button>

                            <Slider
                                step={1}
                                max={300}
                                min={1}
                                value={people}
                                onChange={({value}) => value && setPeople(value)}
                                onFinalChange={({value}) => console.log(value)}
                                overrides={{
                                    InnerThumb: ({$value, $thumbIndex}) => (
                                        <React.Fragment>{$value[$thumbIndex]}</React.Fragment>
                                    ),
                                    ThumbValue: () => null,
                                    Thumb: {
                                        style: () => ({
                                            fontSize: '0.9em',
                                            padding: '3px',
                                            height: '36px',
                                            width: '36px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderTopLeftRadius: '36px',
                                            borderTopRightRadius: '36px',
                                            borderBottomRightRadius: '36px',
                                            borderBottomLeftRadius: '36px',
                                            borderLeftStyle: 'solid',
                                            borderRightStyle: 'solid',
                                            borderTopStyle: 'solid',
                                            borderBottomStyle: 'solid',
                                            borderLeftWidth: '3px',
                                            borderRightWidth: '3px',
                                            borderTopWidth: '3px',
                                            borderBottomWidth: '3px',
                                            borderLeftColor: '#ccc',
                                            borderRightColor: '#ccc',
                                            borderTopColor: '#ccc',
                                            borderBottomColor: '#ccc',
                                            backgroundColor: '#fff',
                                        }),
                                    }, TickBar: ({$min, $max}) => (
                                        <div/>)
                                }}
                            />

                            <Button style={{marginTop: 20, marginLeft: 16}}
                                    size={SIZE.mini}
                                    onClick={() => setPeople([people[0] + 1 > 300 ? 300 : people[0] + 1])}>+</Button>

                        </SliderParagraph>


                        <SliderParagraph>
                            <Label2 style={{width: '300px'}}>Infected per 1000</Label2>

                            <Button style={{marginTop: 20, marginRight: 16}}
                                    size={SIZE.mini}
                                    onClick={() => {
                                        let f = promil[0] - 1 < 1 ? 0 : promil[0] - 1;
                                        setPromil([Number(f.toFixed(2))])
                                    }}>-</Button>

                            <Slider
                                step={1}
                                max={100}
                                min={1}
                                value={promil}
                                onChange={({value}) => value && setPromil(value)}
                                onFinalChange={({value}) => console.log(value)}
                                overrides={{
                                    InnerThumb: ({$value, $thumbIndex}) => (
                                        <React.Fragment>{$value[$thumbIndex]}</React.Fragment>
                                    ),
                                    ThumbValue: () => null,
                                    Thumb: {
                                        style: () => ({
                                            fontSize: '0.9em',
                                            padding: '3px',
                                            height: '36px',
                                            width: '36px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderTopLeftRadius: '36px',
                                            borderTopRightRadius: '36px',
                                            borderBottomRightRadius: '36px',
                                            borderBottomLeftRadius: '36px',
                                            borderLeftStyle: 'solid',
                                            borderRightStyle: 'solid',
                                            borderTopStyle: 'solid',
                                            borderBottomStyle: 'solid',
                                            borderLeftWidth: '3px',
                                            borderRightWidth: '3px',
                                            borderTopWidth: '3px',
                                            borderBottomWidth: '3px',
                                            borderLeftColor: '#ccc',
                                            borderRightColor: '#ccc',
                                            borderTopColor: '#ccc',
                                            borderBottomColor: '#ccc',
                                            backgroundColor: '#fff',
                                        }),
                                    }, TickBar: ({$min, $max}) => (
                                        <div/>)
                                }}
                            />
                            <Button style={{marginTop: 20, marginLeft: 16}}
                                    size={SIZE.mini}
                                    onClick={() => {

                                        let f = promil[0] + 1 > 100 ? 100 : promil[0] + 1;
                                        setPromil([Number(f.toFixed(2))])
                                    }}>+</Button>
                        </SliderParagraph>


                        <SliderParagraph>
                            <Columned>
                                <Label2>Daily growth rate</Label2>
                                <span style={{
                                    color: 'red',
                                    fontSize: '0.5em'
                                }}>*daily increase of the total number of infected</span>
                            </Columned>


                            <Button style={{marginTop: 20, marginRight: 16}}
                                    size={SIZE.mini}
                                    onClick={() => {

                                        let f = double[0] - 0.1 < 0 ? 0 : double[0] - 0.1;
                                        let fp =  Number(f.toFixed(2))
                                        setDouble([fp])
                                    }}>-</Button>

                            <Slider
                                step={0.1}
                                max={100}
                                min={0}
                                value={double}
                                onChange={({value}) => value && setDouble(value)}
                                onFinalChange={({value}) => console.log(value)}
                                overrides={{
                                    InnerThumb: ({$value, $thumbIndex}) => (
                                        <React.Fragment>{$value[$thumbIndex]}%</React.Fragment>
                                    ),
                                    ThumbValue: () => null,
                                    Thumb: {
                                        style: () => ({
                                            fontSize: '0.9em',
                                            padding: '3px',
                                            height: '36px',
                                            width: '36px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderTopLeftRadius: '36px',
                                            borderTopRightRadius: '36px',
                                            borderBottomRightRadius: '36px',
                                            borderBottomLeftRadius: '36px',
                                            borderLeftStyle: 'solid',
                                            borderRightStyle: 'solid',
                                            borderTopStyle: 'solid',
                                            borderBottomStyle: 'solid',
                                            borderLeftWidth: '3px',
                                            borderRightWidth: '3px',
                                            borderTopWidth: '3px',
                                            borderBottomWidth: '3px',
                                            borderLeftColor: '#ccc',
                                            borderRightColor: '#ccc',
                                            borderTopColor: '#ccc',
                                            borderBottomColor: '#ccc',
                                            backgroundColor: '#fff',
                                        }),

                                    }, TickBar: ({$min, $max}) => (
                                        <div/>)
                                }}
                            />

                            <Button style={{marginTop: 20, marginLeft: 16}}
                                    size={SIZE.mini}
                                    onClick={() => {

                                        let f = double[0] + 0.1 > 100 ? 100 : double[0] + 0.1;
                                        let fp =  Number(f.toFixed(2))
                                        setDouble([fp])
                                    }}>+</Button>
                        </SliderParagraph>


                        <Accordion>

                            <Panel title={"Advanced parameters"}>
                                <SliderParagraph>
                                    <Columned>
                                        <Label2>Transmission rate</Label2>
                                        <span style={{color: 'red', fontSize: '0.5em'}}>*Contacts with infected resulting in virus contraction</span>
                                    </Columned>



                                    <Slider
                                        step={0.1}
                                        max={100}
                                        min={0}
                                        value={rateOfTransmission}
                                        onChange={({value}) => value && setRateOfTransmission(value)}
                                        onFinalChange={({value}) => console.log(value)}
                                        overrides={{
                                            InnerThumb: ({$value, $thumbIndex}) => (
                                                <React.Fragment>{$value[$thumbIndex]}%</React.Fragment>
                                            ),
                                            ThumbValue: () => null,
                                            Thumb: {
                                                style: () => ({
                                                    fontSize: '0.9em',
                                                    padding: '3px',
                                                    height: '36px',
                                                    width: '36px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderTopLeftRadius: '36px',
                                                    borderTopRightRadius: '36px',
                                                    borderBottomRightRadius: '36px',
                                                    borderBottomLeftRadius: '36px',
                                                    borderLeftStyle: 'solid',
                                                    borderRightStyle: 'solid',
                                                    borderTopStyle: 'solid',
                                                    borderBottomStyle: 'solid',
                                                    borderLeftWidth: '3px',
                                                    borderRightWidth: '3px',
                                                    borderTopWidth: '3px',
                                                    borderBottomWidth: '3px',
                                                    borderLeftColor: '#ccc',
                                                    borderRightColor: '#ccc',
                                                    borderTopColor: '#ccc',
                                                    borderBottomColor: '#ccc',
                                                    backgroundColor: '#fff',
                                                }),
                                            }, TickBar: ({$min, $max}) => (
                                                <div/>)
                                        }}
                                    />
                                </SliderParagraph>

                                <SliderParagraph>
                                    <Label2 style={{width: '300px', height: '100%'}}>Days</Label2>

                                    <Slider
                                        step={1}
                                        max={30}
                                        min={1}
                                        value={days}
                                        onChange={({value}) => value && setDays(value)}
                                        onFinalChange={({value}) => console.log(value)}
                                        overrides={{
                                            InnerThumb: ({$value, $thumbIndex}) => (
                                                <React.Fragment>{$value[$thumbIndex]}</React.Fragment>
                                            ),
                                            ThumbValue: () => null,
                                            Thumb: {
                                                style: () => ({
                                                    fontSize: '0.9em',
                                                    padding: '3px',
                                                    height: '36px',
                                                    width: '36px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderTopLeftRadius: '36px',
                                                    borderTopRightRadius: '36px',
                                                    borderBottomRightRadius: '36px',
                                                    borderBottomLeftRadius: '36px',
                                                    borderLeftStyle: 'solid',
                                                    borderRightStyle: 'solid',
                                                    borderTopStyle: 'solid',
                                                    borderBottomStyle: 'solid',
                                                    borderLeftWidth: '3px',
                                                    borderRightWidth: '3px',
                                                    borderTopWidth: '3px',
                                                    borderBottomWidth: '3px',
                                                    borderLeftColor: '#ccc',
                                                    borderRightColor: '#ccc',
                                                    borderTopColor: '#ccc',
                                                    borderBottomColor: '#ccc',
                                                    backgroundColor: '#fff',
                                                }),
                                            }, TickBar: ({$min, $max}) => (
                                                <div/>)
                                        }}
                                    />
                                </SliderParagraph>
                            </Panel>
                        </Accordion>


                    </Cell>

                    <Cell span={6}><Chart

                        width={'100%'}
                        height={'500px'}
                        chartType="LineChart"
                        loader={<div>Loading Chart</div>}
                        data={calculateRisk()}
                        options={{
                            chartArea: {'width': '80%', 'height': '85%'},
                            legend: 'none',
                            hAxis: {title: 'Days', titleTextStyle: {color: '#000'}},
                            vAxis: {minValue: 0, format: 'percent'},
                            colors: ['#000'],
                            animation: {
                                duration: 500,
                                easing: 'in',
                                startup: true
                            },
                        }}
                    /></Cell>


                </Grid> : <MiddleScreen><Spinner color={"#000"}/></MiddleScreen>}


                <Footer><Label2>Created with 🖤 by</Label2> <StyledLink
                    href="https://scholar.google.bg/citations?user=x6mjUUUAAAAJ&hl=enn" style={{margin: '8px'}}>
                    Prof. Dr. Danail Brezov
                </StyledLink> & <StyledLink href="https://boby.dev" style={{margin: '8px'}}>
                    Borislav Ivanov Esq.
                </StyledLink> <br/><Label4>All Rights Reserved</Label4></Footer>

                <FooterSecond><Label4>Data source: </Label4> <StyledLink href="https://ourworldindata.org/coronavirus"
                                                                         style={{margin: '8px'}}>
                    Our World in Data
                </StyledLink> </FooterSecond>
            </BaseProvider>
        </StyletronProvider>


    );




    function mean(x) {            // the mean of a dataset {i,x[i]}
        let s = 0;
        let n = x.length;
        for (let xi of x) {
            s += xi;
        }
        if (x === []) {
            return 0;
        } else {
            return s / n;
        }
    }

    function center(x) {           // shifts all data w.r.t. the mean
        const xo = mean(x);
        let y = [];
        for (let xi of x) {
            y.push(xi - xo);
        }
        return y;
    }

    function covar(x, y) {             // covariance of two datasets
        let s = 0;
        let n = x.length;
        if (y.length === n) {

            let xo = center(x);
            let yo = center(y);

            for (let i = 0; i < n; i++) {
                s += xo[i] * yo[i];
            }
            if (n < 50) {
                n -= 1;
            }
            return s / n;
        } else {
            console.log("Dimesnion mismatch, check your data!")
        }
    }

    function cor(x, y) {                       // correlation between two datasets
        if (x.length !== y.length) {
            console.log("Dimesnion mismatch, check your data!")
        } else {
            return covar(x, y) / (covar(x, x) * covar(y, y)) ** (1 / 2);
        }
    }

    function reg(x) {             //yields the slope of liner regression for a data sample {i,x[i]}
        const n = x.length;
        let base = [];
        for (let i = 0; i < n; i++) {
            base.push(i)
        }
        let c = covar(x, base);
        return c / covar(base, base);
    }

    function gfactor(x, k) {                // yields the doubling time relative to a k-days window
        const bs = Math.log(2);
        const n = x.length;

        let d = [];

        for (let i = n - k; i < n; i++) {
            d.push(Math.log(x[i]) / bs);
        }

        return 2 ** reg(d);
    }


    function binom(n) {             // computes binomial coefficients
        let b = [1];
        for (let i = 1; i <= n + 1; i++) {
            if (i > n / 2) {
                b.push(b[n - i])
            } else {
                b.push(b[i - 1] * (n - i + 1) / i)
            }
        }
        return b;
    }

    function prob(p, people, growth, k, r) {        // single day risk for n contacts (k-th day)
        let ss = 0
        p *= growth ** k
        if (p > 1) {
            p = 1
        }
        for (let i = 1; i <= people; i++) {
            ss += binom(people)[i] * p ** i * (1 - p) ** (people - i) * (1 - (1 - r) ** i)
        }

        return ss;
    }


    function risk(promil, people, growth, days, r) {
        const p = promil / 1000
        let t = 1 - p
        const h = [100 * p]
        for (let k = 1; k <= days; k++) {
            if (people !== 0) {
                t = t * (1 - prob(p, people, growth, k, r))
            }
            h.push(100 * (1 - t));
        }
        return h
    }

    function calculateRisk() {
        const ri = risk(promil[0], people[0], double[0] / 100 + 1, days[0], rateOfTransmission[0] / 100);
        return [['Days', 'Risk']].concat(ri.map((x, i) => {

            return [i, x / 100]
        }));
    }
}

